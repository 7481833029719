import Blockquote from '$components/Blockquote'
import Breadcrumbs from '$components/Breadcrumbs'
import Button from '$components/Button'
import Input from '$components/Input'
import Layout from '$components/Layout'
import Link from '$components/Link'
import Page from '$components/Page'
import Paragraph from '$components/Paragraph'
import PostCardRow from '$components/PostCardRow'
import Text from '$components/Text'
import { Post, PostType } from '$utils/globals'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import * as React from 'react'

function getPostsMarkdownQuery(data: {
  allMarkdownRemark: {
    edges: Array<{ node: { frontmatter: Post } }>
  }
}) {
  if (!data) {
    return []
  }
  const posts = data.allMarkdownRemark.edges.map(i => i.node.frontmatter)
  return posts
}

function filterPostsByType(type: PostType, posts: Array<Post>) {
  return posts.filter(p => p.type === type)
}

const Section = styled.div(css`
  padding-bottom: ${rhythm(2)};
`)

const SubSection = styled.div(css`
  padding-bottom: ${rhythm(1)};
`)

function SectionButtons() {
  return (
    <Section>
      <h1> Buttons</h1>
      <SubSection>
        <h2> Standard</h2>
        <div>
          <Button>Primary</Button>
        </div>
        <div>
          <Button componentType={Button.Types.Secondary}>Secondary</Button>
        </div>
        <div>
          <Button componentType={Button.Types.Tertiary}>Tertiary</Button>
        </div>
      </SubSection>
      <SubSection>
        <h2> Edge Cases</h2>
        <div>
          <Button>Go</Button>
        </div>
        <div>
          <Button>Explore Patagonia</Button>
        </div>
      </SubSection>
    </Section>
  )
}

function DesignSystem(props) {
  const { data } = props

  const posts = filterPostsByType(
    PostType.Destination,
    getPostsMarkdownQuery(data),
  )

  if (!posts.length) {
    return null
  }
  return (
    <Layout hideRegistration>
      <Page>
        <SectionButtons />
        <Section>
          <h1>Forms</h1>
          <SubSection>
            <h2>Input</h2>
            <Input type="text" placeholder="placeholder" />
          </SubSection>
          <SubSection>
            <h2>Split Input</h2>
            <div
              css={css`
                max-width: 500px;
              `}
            >
              <Input.SplitContainer>
                <Input type="text" placeholder="placeholder" />
                <Button> Button</Button>
              </Input.SplitContainer>
            </div>
          </SubSection>
        </Section>
        <Section>
          <h1>Post Card</h1>
          <h2>Single</h2>
          <PostCardRow posts={[posts[0]]} />
          <h2>Rows</h2>
          <PostCardRow posts={posts.slice(8, 16)} />
          <h2>Row with title and action</h2>
          <PostCardRow
            title="Explore Patagonia"
            posts={posts.slice(16, 24)}
            renderActions={
              <Button componentType={Button.Types.Secondary}>Explore</Button>
            }
          />
        </Section>
        <Section>
          <h1> Typography with Text component </h1>
          <Text> Default Text </Text>
          <br />
          <Text weight={Text.Weights.Normal}> Weight Normal Text </Text>
          <br />
          <Text weight={Text.Weights.Bold}> Weight Bold Text </Text>
          <br />
          <Text size={Text.Sizes.Small}> Size Small Text </Text>
          <br />
          <Text size={Text.Sizes.Normal}> Size Normal Text </Text>
          <br />
          <Text size={Text.Sizes.Small} weight={Text.Weights.Bold}>
            {' '}
            Size Small/Bold Text{' '}
          </Text>
          <br />
          <Text size={Text.Sizes.Normal} weight={Text.Weights.Bold}>
            {' '}
            Size Normal/Bold Text{' '}
          </Text>
        </Section>
        <Section>
          <h1>Typography</h1>
          <SubSection>
            <h2>Headings</h2>
            <h1>Heading One</h1>
            <h2>Heading Two</h2>
            <h3>Heading Three</h3>
            <h4>Heading Four</h4>
            <h5>Heading Five</h5>
            <h6>Heading Six</h6>
          </SubSection>
          <SubSection>
            <h2> Lead Paragraph</h2>
            <Paragraph type={Paragraph.Types.Lead}>
              Lorem ipsum dolor amet 3 wolf moon 8-bit man bun, kale chips synth
              photo booth gochujang tofu meggings poke affogato retro locavore.
              Typewriter tilde stumptown swag you probably haven't heard of them
              pork belly leggings. Hashtag XOXO air plant put a bird on it,
              bicycle rights meditation cray af next level scenester gochujang
              palo santo authentic. Hammock occupy vinyl activated charcoal
              paleo disrupt. Bitters glossier lomo edison bulb flannel. Vinyl
              unicorn direct trade biodiesel.
            </Paragraph>
          </SubSection>
          <SubSection>
            <h2> Normal Paragraph</h2>
            <Paragraph>
              Lorem ipsum dolor amet 3 wolf moon 8-bit man bun, kale chips synth
              photo booth gochujang tofu meggings poke affogato retro locavore.
              Typewriter tilde stumptown swag you probably haven't heard of them
              pork belly leggings. Hashtag XOXO air plant put a bird on it,
              bicycle rights meditation cray af next level scenester gochujang
              palo santo authentic. Hammock occupy vinyl activated charcoal
              paleo disrupt. Bitters glossier lomo edison bulb flannel. Vinyl
              unicorn direct trade biodiesel.
            </Paragraph>
          </SubSection>
          <SubSection>
            <h2> Blockquote</h2>
            <Blockquote>
              <p>
                “ Travel makes one modest. You see what a tiny place you occupy
                in the world.” - Gustav Flaubert.
              </p>
            </Blockquote>
          </SubSection>
          <SubSection>
            <h2> Links</h2>
            <Link href="#"> Visit Normal Link </Link>
            <Paragraph>
              Paragraph with an embedded <Link href="#">link </Link> to visit.
            </Paragraph>
          </SubSection>
        </Section>
        <Section>
          <h1>Page</h1>
          <SubSection>
            <h2> Breadcrumb </h2>
            <Breadcrumbs post={posts[posts.length - 1]} />
          </SubSection>
        </Section>
      </Page>
    </Layout>
  )
}

export default DesignSystem
export const pageQuery = graphql`
  query DesignSystemQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            featured
            slug
            path
            type
            visibility
            country
            city
            continent
            publishedAt
            title
            image {
              childImageSharp {
                fluid(maxWidth: 220, cropFocus: CENTER, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
